import React from "react"
import withLayout from "../layout"

import Services from "../components/Services"

const ProductsAndServices = () => (
  <>
    <Services />
  </>
)

const customProps = {
  localeKey: "ProductsandServices",
}

export default withLayout(customProps)(ProductsAndServices)
